var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.$t("permission.title.properties", [
              _vm.entity !== null
                ? _vm.$t(`permission.${_vm.entity.toLowerCase()}`)
                : null,
            ]),
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
            "ok-title": _vm.$t("button.select"),
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  [
                    !_vm.readOnly
                      ? _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "success" },
                            on: { click: _vm.ok },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        )
                      : _vm._e(),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: _vm.alertError ? "danger" : "success",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: {
                  icon: _vm.alertError
                    ? ["fas", "triangle-exclamation"]
                    : ["far", "check"],
                },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "b-button-toolbar",
            { staticClass: "py-1 action-toolbar" },
            [
              _c(
                "b-btn",
                {
                  staticClass: "action-button",
                  attrs: { disabled: _vm.readOnly },
                  on: { click: _vm.selectAll },
                },
                [_vm._v(_vm._s(_vm.$t("button.selectall")))]
              ),
              _c(
                "b-btn",
                {
                  staticClass: "action-button",
                  attrs: { disabled: _vm.readOnly },
                  on: { click: _vm.selectNone },
                },
                [_vm._v(_vm._s(_vm.$t("button.selectnone")))]
              ),
            ],
            1
          ),
          _c(
            "b-input-group",
            { staticClass: "mb-2" },
            [
              _c("b-form-input", {
                attrs: { size: "md", placeholder: _vm.$t("dataview.search") },
                on: { input: _vm.applyFilter },
                model: {
                  value: _vm.searchFilter,
                  callback: function ($$v) {
                    _vm.searchFilter = $$v
                  },
                  expression: "searchFilter",
                },
              }),
              _c(
                "b-input-group-append",
                [
                  _c(
                    "b-input-group-text",
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "magnifying-glass"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("ag-grid-vue", {
            staticClass:
              "ag-theme-balham permission-grid-height checklist-grid",
            staticStyle: { width: "100%", height: "400px" },
            attrs: {
              id: "contact-grid",
              gridOptions: _vm.gridOptions,
              animateRows: "",
              autoGroupColumnDef: _vm.autoGroupColumnDef,
              columnDefs: _vm.columnDefs,
              context: _vm.context,
              defaultColDef: _vm.defaultColDef,
              overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              getRowId: (params) => params.data.label,
              groupDefaultExpanded: "",
              rowData: _vm.rowData,
              rowSelection: "multiple",
              rowMultiSelectWithClick: "",
              sideBar: false,
              suppressDragLeaveHidesColumns: "",
              suppressCellFocus: "",
              suppressContextMenu: "true",
              suppressMultiSort: "",
              suppressRowClickSelection: _vm.readOnly,
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }