<template>
  <div>
    <b-modal v-model="modalShow" size="lg" :title="$t('recommendation.title')" footer-class="footerClass"
      @hidden="$emit('update:show', false)"
      content-class="shadow"
      no-close-on-backdrop
    >
      <b-form-checkbox class="setting-checkbox" v-model="settings.allow_over_alloc" size="lg" name="allow_over_alloc">
        {{ $t('recommendation.allow_over_alloc') }}
      </b-form-checkbox>
  
      <b-form-checkbox class="setting-checkbox" v-model="settings.match_staff_based_on_skills" size="lg" name="match_staff_based_on_skills">
        {{ $t('recommendation.match_staff_based_on_skills') }}
      </b-form-checkbox>

      <b-form-checkbox class="indent setting-checkbox" :disabled="true" v-model="settings.include_staff_exact_skill" size="lg" name="include_staff_exact_skill">
        {{ $t('recommendation.include_staff_exact_skill') }}
      </b-form-checkbox>

      <b-form-checkbox class="indent setting-checkbox" :disabled="!settings.match_staff_based_on_skills" v-model="settings.include_staff_higher_skill" size="lg" name="include_staff_higher_skill">
        {{ $t('recommendation.include_staff_higher_skill') }}
      </b-form-checkbox>

      <b-form-checkbox class="indent-two setting-checkbox" :disabled="!settings.match_staff_based_on_skills || !settings.include_staff_higher_skill" v-model="settings.adjust_task_duration_higher" size="lg" name="adjust_task_duration_higher">
        {{ $t('recommendation.adjust_task_duration') }}
      </b-form-checkbox>

      <b-form-checkbox class="indent setting-checkbox" :disabled="!settings.match_staff_based_on_skills" v-model="settings.include_staff_lower_skill" size="lg" name="include_staff_lower_skill">
        {{ $t('recommendation.include_staff_lower_skill') }}
      </b-form-checkbox>

      <b-form-checkbox class="indent-two setting-checkbox" :disabled="!settings.match_staff_based_on_skills || !settings.include_staff_lower_skill"  v-model="settings.adjust_task_duration_lower" size="lg" name="adjust_task_duration_lower">
        {{ $t('recommendation.adjust_task_duration') }}
      </b-form-checkbox>


      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>
  </div>
</template>

<script>
import { recommendationProfileService } from '@/services';

export default {
  name: 'RecommendationSettingsModal',
  props: {
    show:         { type: Boolean, required: true },
    userId:       { type: String, default: "" }
  },
  data() {
    return {
      modalShow: false,
      user: null,
      settings: {
        allow_over_alloc: false,
        match_staff_based_on_skills: true,
        include_staff_exact_skill: true,
        include_staff_higher_skill: true,
        adjust_task_duration_higher: false,
        include_staff_lower_skill: true,
        adjust_task_duration_lower: false
      }
    }
  },
  created() {
    this.settings_template = JSON.parse(JSON.stringify(this.settings));
  },
  beforeDestroy() {
    this.settings_template = null;
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        const self = this;
      
        recommendationProfileService.list(self.userId).then((response) => {  
          const profileData = response.data[response.data.jobCase];
          if (profileData.length === 0) {
            self.createRecommendationProfile();
          }
          else {
            self.settings = profileData[0];
            self.rectifyInvalidSettings();
          }
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
        });

        this.modalShow = newValue;
      }
    }
  },
  computed: {

  },
  methods: {
    rectifyInvalidSettings() {
      for (const prop in this.settings_template) {
        if (typeof this.settings[prop] != 'boolean') {
          this.settings[prop] = this.settings_template[prop];
        }
      }
    },
    createRecommendationProfile() {
      recommendationProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
    },
    ok() {
        recommendationProfileService.update([this.settings],
                          this.userId).then((response) => {  
          self.profileData = response.data;
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
        });
        
        this.$emit('update:show', false);
        this.$emit('success', this.settings );
    }
  }
}
</script>

<style lang="scss">
.indent {
  margin-left: 1.5em;
}

.indent-two {
  margin-left: 3em;
}

.setting-checkbox {
  font-size: 1em !important;
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 2.3 !important;
}
</style>